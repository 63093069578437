/* Disabled because bootstrap theme cells do not have background and other cells appear under the stickied */

/*.table-sticky-first-col th:first-child,
.table-sticky-first-col td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}
.table-sticky-last-col th:first-child,
.table-sticky-last-col td:first-child {
    position: sticky;
    right: 0;
    z-index: 1;
}*/

.footer {
    background-color: #f5f5f5;
}

.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
}

main {
    padding-top: 30px;
}